@import '../../variable.scss';

.form-group {
  margin-bottom: 10px;
  width: 100%;
  position: relative;
  .form-label {
    font-size: 10px;
    font-weight: 600;
    color: $hit_gray;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .form-control {
    border-color: $primary_border_color;
    color: $primary_color;
    min-height: 40px;
    &::placeholder {
      font-size: 16px;
      font-style: normal;
      color: $border_color;
      font-weight: 600;
    }
    &.disabled {
      background: $black_squeeze;
      border-color: $primary_border_color;
      opacity: 1;
      color: $border_color;
      pointer-events: inherit;
      cursor: not-allowed;
    }
  }
}

.missing_field {
  border-color: $danger_hover_color !important;
}

.error {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: -8px;
  color: $danger_color;
  line-height: 15px;
}

.inner_label {
  position: absolute !important;
  top: 0 !important;
  z-index: 2;
  right: 100%;
  transform: translateX(calc(100% + 12px));
  transform-origin: center top;
  white-space: nowrap;
  transition: all 250ms ease !important;
  &_hidden {
    font-size: 0;
    transform: translateX(calc(100% + 12px)) scaleY(0);
  }
}