@import "../../variable.scss";

.radiobtn_area {
  cursor: pointer;
  font-size: 14px;
  color: $primary_color;
  max-width: 300px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  .form-check-label {
    text-transform: none;
    font-size: 16px;
    color: $primary_color;
    margin-left: 6px;
    line-height: 20px;
    &:hover {
      cursor: pointer;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      z-index: 21;
      width: 20px;
      height: 20px;
      line-height: 20px;
      background: url(../../stories/assets/ic_Radio_Checked.svg);
      background-size: 100%;
      border: 0;
      padding: 0;
      border-radius: 0;
      display: none;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      z-index: 20;
      width: 20px;
      height: 20px;
      line-height: 20px;
      background: url(../../stories/assets/ic_Radio_Unchecked.svg);
      background-size: 100%;
      border: 0;
      padding: 0;
      border-radius: 0;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin-top: 0;
    &:checked ~ label.form-check-label {
      background-color: $white;
    }
    &:checked ~ label.form-check-label::after {
      display: block;
    }
    &:disabled ~ label.form-check-label {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

.more_filters {
  &_label {
    font-size: 12px;
    text-transform: uppercase;
    color: $hit_gray;
    margin-bottom: 12px;
  }
  &_checkbox {
    .form-check-label {
      font-size: 16px !important;
    }
  }
  &_input {
    max-width: 290px;
  }
}
