@import '../../variable.scss';

.custom_dropdown {
  button {
    transition: all 0.2s ease-in-out;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 9px 30px 9px 10px;
  }
  button:disabled, button:focus {
    background: $black_squeeze;
    border-color: $primary_border_color;
    opacity: 1;
    color: $border_color;
    pointer-events: inherit;
    cursor: not-allowed;
  }
  button:focus {
    color: $primary_color;
  }

  .btn-primary {
    background-color: $white;
    border: 1px solid $primary_border_color;
    text-align: left;
    color: $primary_color;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    min-height: 40px;
    &:hover,
    &:focus,
    &.active {
      box-shadow: none;
      outline: none;
    }
    &:focus,
    &.active {
      &.dropdown-toggle::after {
        background: url(../../stories/assets/ic_Dropdown_Blue.svg) 50% 50%
          no-repeat;
        background-size: contain;
        border-width: 0;
        border: 0;
      }
    }
  }
  .dropdown-toggle::after {
    font-size: 0;
    background: url(../../stories/assets/ic_Dropdown_Main.svg) 50% 50%
      no-repeat;
    background-size: contain;
    width: 11px;
    height: 7px;
    position: absolute;
    top: 16px;
    right: 9px;
    border: 0;
  }
  .dropdown-menu {
    width: 100%;
    min-width: 200px;
    top: 8px !important;
    border-color: $border_color;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    padding: 5px 0;

    .dropdown-divider {
      margin: 3px 0;
    }
    .dropdown-item {
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden;
      &:active {
        background-color: transparent;
      }
      .form-check {
        display: flex;
        align-items: center;
        input[type="checkbox"],
        input[type="checkbox"]:checked {
          visibility: hidden;
          & + .form-check-label {
            position: relative;
            margin-left: 5px;
            color: $primary_color;
            white-space: normal;
            &:hover {
              cursor: pointer;
            }
            &::before {
              position: absolute;
              display: block;
              content: "";
              transform: translateY(-50%);
              top: 50%;
              left: -25px;
              width: 20px;
              height: 20px;
              background: url(../../stories/assets/ic_checkbox_unselected.svg)
                50% 50% no-repeat;
              border: 1px solid $primary_border_color;
              border-radius: 3px;
            }
          }
        }
        input[type="checkbox"]:checked {
          & + .form-check-label {
            &::before {
              background: url(../../stories/assets/ic_checkbox_selected.svg)
                50% 50% no-repeat;
            }
          }
        }
      }
    }
  }
}