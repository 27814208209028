@import '../../variable.scss';

.action {
  color: $border_color;
  font-size: 19px;
  line-height: 1;
  vertical-align: middle !important;
}

.ordinaryRow:hover {
  cursor: pointer;
}

.chosenRow {
  background-color: $black_squeeze;
  &:hover {
    cursor: pointer;
  }
}

.thumb_initial {
  text-transform: uppercase;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
  background: $primary_border_color;
  color: $hit_gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab_mask {
  width: 30px;
  height: 30px;
  float: left;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  color: $nevada;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
}

.tab_img{
  border-radius: 50%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: 50% 50% !important;
  overflow: hidden;
}

.global_edit {
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  color: $white;
  text-decoration: none;
  background-color: $secondary_color;
  display: inline-block;
  border-radius: 4px;
  visibility: visible;
  cursor: pointer;
}

.global_close {
  cursor: pointer;
  margin-top: -3px;
}
