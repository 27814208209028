@use "sass:map";
@import '../../variable.scss';

.link {
  $rules: (
    "basic": (
      "bg": transparent,
      "border": none,
      "color": $secondary_color,
      "bgHover": transparent,
      "borderHover": none,
      "colorHover": $primary_color,
    ),
    // NOTE: add styles for other link variant
    "secondary": (
      "bg": green,
      "border": 1px solid white,
      "color": white,
      "bgHover": yellow,
      "borderHover": yellow,
      "colorHover": black,
    ),
  );

  @each $type, $rule in $rules {
    &_#{$type} {
      background-color: map-get(map-get($rules, $type), 'bg') !important;
      border: map-get(map-get($rules, $type), 'border') !important;
      color: map-get(map-get($rules, $type), 'color') !important;
      font-weight: 600 !important;
  
      &:focus, &:hover {
        background-color: map-get(map-get($rules, $type), 'bgHover') !important;
        border: map-get(map-get($rules, $type), 'borderHover') !important;
        box-shadow: none !important;
        color: map-get(map-get($rules, $type), 'colorHover') !important;
        text-decoration: underline !important;
      }
  
      &:active,
      &:active:focus,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled):active {
        background-color: map-get(map-get($rules, $type), 'bgHover') !important;
        border: map-get(map-get($rules, $type), 'borderHover') !important;
        box-shadow: none !important;
        color: map-get(map-get($rules, $type), 'colorHover') !important;
        text-decoration: underline !important;
      }
  
      &:active:hover {
        background-color: map-get(map-get($rules, $type), 'bgHover') !important;
        border: map-get(map-get($rules, $type), 'borderHover') !important;
        box-shadow: none !important;
        color: map-get(map-get($rules, $type), 'colorHover') !important;
        text-decoration: underline !important;
      }
  
      &:disabled {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        color: map-get(map-get($rules, $type), 'colorHover') !important;
        text-decoration: none !important;
        opacity: 0.3 !important;
        cursor: not-allowed !important;
      }
    }
  }
};
