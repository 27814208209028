@import '../../variable.scss';

.custom_modal {
  .modal-dialog {
    display: flex;
    justify-content: center;

    .modal-content {
      max-width: 600px;
      min-width: 600px;
      border: 1px solid $primary_border_color;
      box-shadow: 0 0 0;

      .modal-body {
        padding: 15px;
      }

      .modal-header {
        background-color: $black_squeeze;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .close {
          position: absolute;
          top: 22px;
          right: 20px;
          color: $hit_gray;
          text-shadow: 0 0 0;
          font-size: 24px;
          font-weight: 700;
          margin-left: 15px;
          opacity: 1;
          &:hover {
            color: $hit_gray;
          }
        }

        .modal-title {
          font-size: 24px;
          font-weight: 700;
          color: $primary_color;
        }
      }
      .modal-footer {
        border-top: 1px solid $primary_border_color;
        padding: 15px;
        > * {
          margin: 0;
        }
        .btn {
          max-width: 85px;
          min-width: 85px;
        }
      }
    }
  }
  $all-widths: (
    small: 378px,
    large: 890px,
  );
  @each $key, $width in $all-widths {
    &_#{$key} {
      .modal-content {
        max-width: $width !important;
        min-width: $width !important;
      }
    }
  }
  .modal-fullscreen {
    max-width: none !important;
    transition: none;
    transform:none;
    width: 100%;
    margin: 0;
    height: 100vh;
    .modal-content {
      max-width: none !important;
      min-width: none !important;
    }
  }
}
