@import '../../variable.scss';

.date_picker_area {
  position: relative;
  label {
    position: absolute;
    top: -14px;
    left: 0;
    color: $hit_gray;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .date_icon {
    display: inline-block;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    left: 12px;
    .fa-calendar-alt {
      color: $secondary_color;
      font-size: 16px;
    }
  }
  &.disabled {
    .date_icon {
      .fa-calendar-alt {
        color: $black_squeeze;
      }
    }
  }
}

.custom_date_picker {
  input {
    position: relative;
    z-index: 1;
    min-width: 120px;
    padding-left: 32px;
    padding-right: 4px;
    height: 40px;
    border-color: $primary_border_color;
    cursor: pointer;
    transition: all 320ms ease;
    &:disabled {
      background-color: $black_squeeze;
      cursor: not-allowed;
      color: $border_color !important;
    }
    &:read-only {
      background-color: $white;
    }
  }
  .rdtPicker {
    border-radius: 5px;
    border-color: $border_color;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: auto;
    min-width: 255px;
    max-width: 255px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    th {
      border-bottom: 0;
      &.rdtPrev {
        &:hover {
          border-radius: 3px;
        }
        &::before {
          content: '';
          display: block;
          margin: 11px auto;
          width: 9px;
          height: 9px;
          font-weight: 600;
          border-top: 3px solid $primary_color;
          border-left: 3px solid $primary_color;
          transform: rotate(-45deg);
        }
        span {
          display: none;
        }
      }
      &.rdtNext {
        &:hover {
          border-radius: 3px;
        }
        &::before {
          content: '';
          display: block;
          margin: 11px auto;
          width: 9px;
          height: 9px;
          font-weight: 600;
          border-top: 3px solid $primary_color;
          border-left: 3px solid $primary_color;
          transform: rotate(135deg);
        }
        span {
          display: none;
        }
      }
    }
    td {
      font-size: 14px;
      border: 2px solid $white;
      &.rdtToday {
        background-color: $gray_g;
        border-color: $white;
        border-radius: 0;
        color: $primary_color;
        border-radius: 3px;
        &::before {
          display: none;
        }
        &:hover {
          border-color: $white !important;
        }
      }
      &.rdtActive,
      &.rdtActive:hover {
        background-color: $secondary_color;
        border-color: $white !important;
        color: $white;
      }
      &.rdtDisabled {
        background: $black_squeeze;
        border-radius: 3px;
        color: $primary_color;
        font-weight: 400;
        opacity: 0.3;
        border: 2px solid $white !important;
        &:hover {
          background-color: $white;
          border: 2px solid $white !important;
          cursor: not-allowed;
        }
      }
      &.rdtDay {
        &:hover {
          border-radius: 3px;
          border: 2px solid $gray_b;
        }
      }
      &.rdtTimeToggle {
        font-size: 14px !important;
        color: $secondary_color !important;
        font-weight: 600 !important;
      }
    }
    .dow {
      color: $gray_h;
      font-size: 85%;
    }
  }
  .rdtSwitch {
    font-size: 14px;
    color: $secondary_color;
    font-weight: 600;
    width: auto;
    height: auto;
    cursor: pointer;
    &:hover {
      background: transparent !important;
    }
  }
}