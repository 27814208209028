@import '../../variable.scss';

.filter_bar {
  min-width: 610px;
}

.content_area {
  &.job_reports {
    background-color: $white;

    .job_reports_wrapper {
      width: 100%;
      padding: 33px 15px 18px;
      .search {
        max-width: 335px;
        min-width: 335px;
        & > div > input {
          height: 40px;
          border-color: #d6e1e5;
          padding: 0 10px;
        }
      }
      .filters {
        min-width: 220px;
        .input {
          max-width: 190px;
          // min-width: 125px;
        }

        .time_separation {
          height: 40px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.service_reports_title {
  font-size: 36px !important;
}

.table {
  color: $primary_color !important;
  border: 1px solid $primary_border_color;
  border-radius: 4px;
  border-collapse: inherit;
  border-spacing: 0;
  margin-bottom: 0 !important;
  td {
    vertical-align: middle !important;
  }
  tbody > tr > td {
    vertical-align: middle !important;
    &.no_data_found {
      min-height: 150px;
      height: 150px;
      vertical-align: middle !important;
      padding: 15px;
      font-size: 16px;
      color: $hit_gray;
      text-align: center;
    }
  }
}

.table_header {
  background-color: $concrete;
  border-bottom: 1px solid $primary_border_color;
  td {
    border-top: none !important;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.action {
  color: $border_color;
  font-size: 19px;
  line-height: 1;
  vertical-align: middle !important;
}

.ordinaryRow:hover {
  cursor: pointer;
}

.chosenRow {
  background-color: $black_squeeze;
  &:hover {
    cursor: pointer;
  }
}

.job_details_text {
  color: $hit_gray;
}

@mixin before_item {
  position: absolute;
  display: block;
  top: 0;
  left: -18.5px;
  width: 28px;
  height: 28px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  background: $secondary_color;
  color: $white;
  border: 5px solid $primary_border_color;
  border-radius: 50%;
  display: inline-block;
  z-index: 2;
}
.job_history_container {
  min-width: 240px;
}

.job_history {
  & > div {
    position: relative;
    border-left: 7px solid $primary_border_color;
    box-sizing: border-box;

    &:before {
      @include before_item;
      content: attr(data-stage);
    }

    &:last-child {
      border-color: transparent;
    }
  }
}

.reports_search {
  position: absolute;
  right: 15px;
  top: 0;
  background: $secondary_color;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 0 4px 4px 0;
  transition: opacity 250ms ease;
  cursor: pointer;
  .fa-search {
    color: $white;
    font-size: 15px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.reports_more_filters {
  background-color: transparent !important;
  color: $secondary_color !important;
  height: 40px !important;
  width: 105px !important;
  font-weight: 400 !important;
  padding: 5px 15px 5px 5px !important;
  background: url('../../stories/assets/ic_Dropdown_Blue.svg') no-repeat right 18px;
  &:focus {
    box-shadow: none !important;
    border-color: none !important;
  }
}

.modal_text {
  color: $hit_gray;
  font-size: 14px;
  font-weight: 400;
}

.missing_field {
  border-color: $danger_hover_color !important;
}

.report_detail {
  &_key {
    width: 170px;
    color: $hit_gray;
    font-weight: 400;
  }
  &_value {
    color: $primary_color;
    font-weight: 600;
  }
}

.download_receipt_btn {
  border: none;
  background-color: transparent;
  color: $secondary_color;
  font-weight: 600;
  &:hover {
    color: $primary_color;
    text-decoration: underline;
  }
}
