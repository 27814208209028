@import '../../variable.scss';

.custom_dropdown {
  .btn-primary {
    background-color: $white;
    border: 1px solid $primary_border_color;
    border-radius: 4px;
    text-align: left;
    color: $primary_color;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    min-height: 40px;
    &:hover,
    &:focus,
    &.active {
      box-shadow: none;
      outline: none;
    }
    &:focus,
    &.active {
      &.dropdown-toggle::after {
        background: url(../../stories/assets/ic_Dropdown_Blue.svg) 50% 50%
          no-repeat;
        background-size: contain;
        border-width: 0;
        border: 0;
      }
    }
    &.disabled {
      background: $black_squeeze;
      border-color: $primary_border_color;
      opacity: 1;
      color: $border_color;
      pointer-events: inherit;
      cursor: not-allowed;
    }
    &.missing_field {
      border-color: $danger_hover_color;
    }
  }
  .dropdown-toggle {
    width: 100%;
  }
  .dropdown-toggle::after {
    font-size: 0;
    background: url(../../stories/assets/ic_Dropdown_Main.svg) 50% 50%
      no-repeat;
    background-size: contain;
    width: 11px;
    height: 7px;
    position: absolute;
    top: 16px;
    right: 9px;
    border: 0;
  }
  .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    top: 8px !important;
    border-color: $border_color;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    padding: 5px 0;

    .dropdown-divider {
      margin: 3px 0;
    }
    .dropdown-item {
      padding-left: 10px;
      padding-right: 10px;
      &:hover {
        background-color: $secondary_color;
        color: $white;
      }
    }
  }
}