@import '../../variable.scss';

@keyframes spin {
  100% { transform: translate(-50%, -50%) rotate(360deg) };
}

.loader {
  &_custom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 0.25rem solid $white;
    border-radius: 50%;
    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 0.5rem);
      height: calc(100% + 0.5rem);
      border: 0.25rem solid $secondary_color;
      border-right-color: transparent;
      border-radius: 50%;
      animation: 1s linear infinite spin;
    }
  }
  &.show {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.15);
  }
  &.hide {
    display: none;
  }
  img {
    width: 50px;
    position: absolute;
    top: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
  }
}