@import '../../variable.scss';

.file_description {
  min-height: 80px !important;
}

.file_upload_error {
  width: 567px;
  background: $light_peach;
  font-weight: 400;
  color: $pumpkin;
  font-size: 16px;
  padding: 13px 5px;
  border-radius: 4px;
  span {
    display: inline-block;
    margin: auto;
    text-align: center;
    width: 99%;
  }
}

.progress {
  color: $gray_e;
  &_bar {
    height: 12px;
    border-radius: 6px;
    width: 170px;
  }
}

.drop_area {
  width: 100%;
  border: 1px dashed $border_color;
  padding: 24px 15px 34px 15px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  color: $nevada !important;

  .upload_icon {
    color: $border_color;
    font-size: 25px;
  }

  h3 {
    line-height: 1.1;
    font-size: 16px;
    color: $nevada;
  }

  .file_input {
    color: $secondary_color;
    display: inline-block;
    padding-left: 5px;
    transition: all 250ms ease;
    margin: 0;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      transition: all 250ms ease;
    }

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
    }
  }

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: $hit_gray;
    text-align: center;
    font-size: 14px;
    margin-bottom: 3px;
  }
}

.attach_document_form {
  input::placeholder,
  textarea::placeholder {
    font-weight: 400 !important;
  }
}

.close_icon {
  z-index: 20;
  color: $gray_e;
  transition: color 250ms ease;
  &:hover {
    cursor: pointer;
    color: $secondary_color !important;
  }
}
