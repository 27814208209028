@use "sass:map";
@import '../../variable.scss';

.button_primary {
  background-color: $tree_poppy;
  border-color: $tree_poppy;
  &.disabled {
    background-color: $tree_poppy;
    border-color: $tree_poppy;
    cursor: no-drop;
    pointer-events: none;
  }
  &:focus {
    background-color: $zest;
    border-color: $tree_poppy;
    box-shadow: none;
  }

  &:hover {
    background-color: $zest;
    border-color: $tree_poppy;
  }
  &:disabled {
    background-color: $zest;
    border-color: $tree_poppy;
  }

  &:active,
  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $zest;
    border-color: $tree_poppy;
    box-shadow: none;
  }

  &:active:hover {
    background-color: $zest;
    border-color: $tree_poppy;
    color: $white;
    box-shadow: none;
  }
}



.button {
  $rules: (
    "secondary": (
      "bg": $white,
      "borderColor": $primary_border_color,
      "color": $secondary_color,
      "bgHover": $black_squeeze,
      "colorHover": $primary_color,
    ),
    "primary_blue": (
      "bg": $secondary_color,
      "borderColor": $primary_border_color,
      "color": $white,
      "bgHover": $black_squeeze,
      "colorHover": $primary_color,
    ),
  );

  @each $type, $rule in $rules {
    &_#{$type} {
      background-color: map-get(map-get($rules, $type), 'bg') !important;
      border: 1px solid map-get(map-get($rules, $type), 'borderColor') !important;
      color: map-get(map-get($rules, $type), 'color') !important;
      padding: 9px 10px !important;
      min-height: 40px !important;
      min-width: 172px;
      max-width: 172px;
      font-weight: 600;

      &:focus,
      &:hover,
      &:active,
      &:active:focus,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled):active,
      &:active:hover {
        background-color: map-get(map-get($rules, $type), 'bgHover') !important;
        border-color: map-get(map-get($rules, $type), 'borderColor') !important;
        box-shadow: none !important;
        color: map-get(map-get($rules, $type), 'colorHover') !important;
      }

      &:disabled {
        background-color: map-get(map-get($rules, $type), 'bg') !important;
        border: 1px solid map-get(map-get($rules, $type), 'borderColor') !important;
        color: map-get(map-get($rules, $type), 'color') !important;
        opacity: 0.3 !important;
        cursor: not-allowed !important;
      }
    }
  }
};

.arrow_button {
  background-color: transparent !important;
  color: $secondary_color !important;
  height: 40px !important;
  font-weight: 400 !important;
  padding: 5px 15px 5px 5px !important;
  background: url('../../stories/assets/ic_Dropdown_Blue.svg') no-repeat right 18px;
  border: none !important;
  &:focus {
    box-shadow: none !important;
    border: none !important;
  }
}