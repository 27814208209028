@import '../../variable.scss';

.search {
  width: 100%;
  min-width: 190px;
  & > div > input {
    height: 40px;
    border-color: $primary_border_color;
    padding: 0 10px;
    position: relative;
  }

  .search_button {
    position: absolute;
    right: 0;
    top: 0;
    background: $secondary_color;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 0 4px 4px 0;
    transition: opacity 250ms ease;
    cursor: pointer;
    .fa-search {
      color: $white;
      font-size: 15px;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
