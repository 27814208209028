@import "../../variable.scss";

.page-item {
  width: 40px;
  height: 40px;

  .page-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: $secondary_color;

    &:focus {
      box-shadow: none;
    }
  }
}

.page-item:hover {
  .page-link {
    color: $primary_color;
  }
}

.page-item.disabled {
  z-index: 3;
  .page-link {
    color: $border_color !important;
  }
  &:hover {
    cursor: not-allowed;
    .page-link {
      background-color: $gray_i;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.page-item.active {
  z-index: 3;
  .page-link {
    color: $primary_color !important;
    background-color: white !important;
    border-color: #dee2e6 !important;
  }
}

.direction_button > a > span,
.direction_button > span > span {
  font-size: 28px;
  line-height: 1;
  padding-bottom: 5px;
}
